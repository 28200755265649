import Vue from "vue";
import Router from "vue-router";
import NotFound from "./pages/_notFound.vue";
import store from "./store";

const PassThrough = {
  render(c) {
    return c("router-view");
  },
};

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: "/",
  routes: [
    {
      path: "/",
      beforeEnter(to, from, next) {
        store.dispatch("AUTHENTICATE").then(() => {
          if (store.state.me) return next("/admin");
          else return next("/login");
        });
      },
    },
    {
      path: "/admin",
      component: () => import("./pages/admin/_layout.vue"),
      redirect: "admin/events",

      beforeEnter(to, from, next) {
        store.dispatch("AUTHENTICATE").then(() => {
          if (store.state.me) return next();
          else return next(`/login?redirect=${to.fullPath}`);
        });
      },
      children: [
        {
          path: "",
          components: {
            default: PassThrough,
            menu: () => import("./pages/admin/_menu.vue"),
          },
          children: [
            {
              path: "",
              redirect: "events",
            },
            {
              path: "events",
              component: () => import("./pages/admin/_layout-list.vue"),
              children: [
                {
                  path: "",
                  component: () => import("./pages/admin/events-list.vue"),
                  meta: { entity: "events" },
                },
              ],
            },
            {
              path: "events/create",
              component: () => import("./pages/admin/_layout-manage.vue"),
              children: [
                {
                  path: "",
                  component: () => import("./pages/admin/events-create.vue"),
                  meta: { entity: "events" },
                },
              ],
            },
            {
              path: "charities",
              component: () => import("./pages/admin/_layout-list.vue"),
              children: [
                {
                  path: "",
                  component: () => import("./pages/admin/charities-list.vue"),
                  meta: { entity: "charities" },
                },
              ],
            },
            {
              path: "charities/create",
              component: () => import("./pages/admin/_layout-manage.vue"),
              children: [
                {
                  path: "",
                  component: () => import("./pages/admin/charities-manage.vue"),
                  meta: { entity: "charities" },
                },
              ],
            },
            {
              path: "charities/:itemId/edit",
              component: () => import("./pages/admin/_layout-manage.vue"),
              children: [
                {
                  path: "",
                  component: () => import("./pages/admin/charities-manage.vue"),
                  meta: { entity: "charities" },
                },
              ],
            },
            {
              path: "users",
              component: () => import("./pages/admin/_layout-list.vue"),
              children: [
                {
                  path: "",
                  component: () => import("./pages/admin/users-list.vue"),
                  meta: { entity: "users" },
                },
              ],
            },
            {
              path: "users/create",
              component: () => import("./pages/admin/_layout-manage.vue"),
              children: [
                {
                  path: "",
                  component: () => import("./pages/admin/users-manage.vue"),
                  meta: { entity: "users" },
                },
              ],
            },
            {
              path: "users/:itemId/edit",
              component: () => import("./pages/admin/_layout-manage.vue"),
              children: [
                {
                  path: "",
                  component: () => import("./pages/admin/users-manage.vue"),
                  meta: { entity: "users" },
                },
              ],
            },
          ],
        },
        {
          path: "event/:eventId",
          components: {
            default: PassThrough,
            menu: () => import("./pages/admin/event/_menu.vue"),
          },
          children: [
            {
              path: "",
              redirect: "members",
            },
            {
              path: "members",
              component: () => import("./pages/admin/_layout-list.vue"),
              children: [
                {
                  path: "",
                  component: () => import("./pages/admin/event/members-list.vue"),
                  meta: { entity: "members" },
                },
              ],
            },
            {
              path: "members/:memberId/properties",
              component: () => import("./pages/admin/_layout-properties.vue"),
              children: [
                {
                  path: "",
                  component: () => import("./pages/admin/event/members-properties.vue"),
                  meta: { entity: "members" },
                },
              ],
            },
            {
              path: "members/create",
              component: () => import("./pages/admin/_layout-manage.vue"),
              children: [
                {
                  path: "",
                  component: () => import("./pages/admin/event/members-manage.vue"),
                  meta: { entity: "members" },
                },
              ],
            },
            {
              path: "members/import",
              component: () => import("./pages/admin/_layout-properties.vue"),
              children: [
                {
                  path: "",
                  component: () => import("./pages/admin/event/members-import.vue"),
                  meta: { entity: "members" },
                },
              ],
            },
            {
              path: "members/:itemId/edit",
              component: () => import("./pages/admin/_layout-manage.vue"),
              children: [
                {
                  path: "",
                  component: () => import("./pages/admin/event/members-manage.vue"),
                  meta: { entity: "members" },
                },
              ],
            },
            {
              path: "referents",
              component: () => import("./pages/admin/_layout-list.vue"),
              children: [
                {
                  path: "",
                  component: () => import("./pages/admin/event/referents-list.vue"),
                  meta: { entity: "referents" },
                },
              ],
            },
            {
              path: "referents/:memberId/properties",
              component: () => import("./pages/admin/_layout-properties.vue"),
              children: [
                {
                  path: "",
                  component: () => import("./pages/admin/event/members-properties.vue"),
                  meta: { entity: "referents" },
                },
              ],
            },
            {
              path: "referents/create",
              component: () => import("./pages/admin/_layout-manage.vue"),
              children: [
                {
                  path: "",
                  component: () => import("./pages/admin/event/referents-manage.vue"),
                  meta: { entity: "referents" },
                },
              ],
            },
            {
              path: "referents/import",
              component: () => import("./pages/admin/_layout-properties.vue"),
              children: [
                {
                  path: "",
                  component: () => import("./pages/admin/event/referents-import.vue"),
                  meta: { entity: "referents" },
                },
              ],
            },
            {
              path: "referents/:itemId/edit",
              component: () => import("./pages/admin/_layout-manage.vue"),
              children: [
                {
                  path: "",
                  component: () => import("./pages/admin/event/referents-manage.vue"),
                  meta: { entity: "referents" },
                },
              ],
            },
            {
              path: "organizations",
              component: () => import("./pages/admin/_layout-list.vue"),
              children: [
                {
                  path: "",
                  component: () => import("./pages/admin/event/organizations-list.vue"),
                  meta: { entity: "organizations" },
                },
              ],
            },
            {
              path: "organizations/create",
              component: () => import("./pages/admin/_layout-manage.vue"),
              children: [
                {
                  path: "",
                  component: () => import("./pages/admin/event/organizations-manage.vue"),
                  meta: { entity: "organizations" },
                },
              ],
            },
            {
              path: "organizations/:itemId/edit",
              component: () => import("./pages/admin/_layout-manage.vue"),
              children: [
                {
                  path: "",
                  component: () => import("./pages/admin/event/organizations-manage.vue"),
                  meta: { entity: "organizations" },
                },
              ],
            },
            {
              path: "challenges",
              component: () => import("./pages/admin/_layout-list.vue"),
              children: [
                {
                  path: "",
                  component: () => import("./pages/admin/event/challenges-list.vue"),
                  meta: { entity: "challenges" },
                },
              ],
            },
            {
              path: "challenges/create",
              component: () => import("./pages/admin/_layout-manage.vue"),
              children: [
                {
                  path: "",
                  component: () => import("./pages/admin/event/challenges-manage.vue"),
                  meta: { entity: "challenges" },
                },
              ],
            },
            {
              path: "challenges/:itemId/edit",
              component: () => import("./pages/admin/_layout-manage.vue"),
              children: [
                {
                  path: "",
                  component: () => import("./pages/admin/event/challenges-manage.vue"),
                  meta: { entity: "challenges" },
                },
              ],
            },
            {
              path: "news",
              component: () => import("./pages/admin/_layout-list.vue"),
              children: [
                {
                  path: "",
                  component: () => import("./pages/admin/event/news-list.vue"),
                  meta: { entity: "news" },
                },
              ],
            },
            {
              path: "news/create",
              component: () => import("./pages/admin/_layout-manage.vue"),
              children: [
                {
                  path: "",
                  component: () => import("./pages/admin/event/news-manage.vue"),
                  meta: { entity: "news" },
                },
              ],
            },
            {
              path: "news/:itemId/edit",
              component: () => import("./pages/admin/_layout-manage.vue"),
              children: [
                {
                  path: "",
                  component: () => import("./pages/admin/event/news-manage.vue"),
                  meta: { entity: "news" },
                },
              ],
            },
            {
              path: "stats",
              component: () => import("./pages/admin/_layout-list.vue"),
              children: [
                {
                  path: "",
                  component: () => import("./pages/admin/event/stats-list.vue"),
                  meta: { entity: "stats" },
                },
              ],
            },
            {
              path: "steps",
              component: () => import("./pages/admin/_layout-list.vue"),
              children: [
                {
                  path: "",
                  component: () => import("./pages/admin/event/steps-list.vue"),
                  meta: { entity: "steps" },
                },
              ],
            },
            {
              path: "bonus",
              component: () => import("./pages/admin/_layout-list.vue"),
              children: [
                {
                  path: "",
                  component: () => import("./pages/admin/event/bonus-list.vue"),
                  meta: { entity: "bonus" },
                },
              ],
            },
            {
              path: "bonus/create",
              component: () => import("./pages/admin/_layout-manage.vue"),
              children: [
                {
                  path: "",
                  component: () => import("./pages/admin/event/bonus-manage.vue"),
                  meta: { entity: "bonus" },
                },
              ],
            },
            {
              path: "bonus/import",
              component: () => import("./pages/admin/_layout-properties.vue"),
              children: [
                {
                  path: "",
                  component: () => import("./pages/admin/event/bonus-import.vue"),
                  meta: { entity: "bonus" },
                },
              ],
            },
            {
              path: "bonus/:itemId/edit",
              component: () => import("./pages/admin/_layout-manage.vue"),
              children: [
                {
                  path: "",
                  component: () => import("./pages/admin/event/bonus-manage.vue"),
                  meta: { entity: "bonus" },
                },
              ],
            },
            {
              path: "photo_categories",
              component: () => import("./pages/admin/_layout-list.vue"),
              children: [
                {
                  path: "",
                  component: () => import("./pages/admin/event/photo_categories-list.vue"),
                  meta: { entity: "photo_categories" },
                },
              ],
            },
            {
              path: "photo_categories/create",
              component: () => import("./pages/admin/_layout-manage.vue"),
              children: [
                {
                  path: "",
                  component: () => import("./pages/admin/event/photo_categories-manage.vue"),
                  meta: { entity: "photo_categories" },
                },
              ],
            },
            {
              path: "photo_categories/:itemId/edit",
              component: () => import("./pages/admin/_layout-manage.vue"),
              children: [
                {
                  path: "",
                  component: () => import("./pages/admin/event/photo_categories-manage.vue"),
                  meta: { entity: "photo_categories" },
                },
              ],
            },
            {
              path: "activities",
              component: () => import("./pages/admin/_layout-list.vue"),
              children: [
                {
                  path: "",
                  component: () => import("./pages/admin/event/activities-list.vue"),
                  meta: { entity: "activities" },
                },
              ],
            },
            {
              path: "activities/create",
              component: () => import("./pages/admin/_layout-manage.vue"),
              children: [
                {
                  path: "",
                  component: () => import("./pages/admin/event/activities-manage.vue"),
                  meta: { entity: "activities" },
                },
              ],
            },
            {
              path: "activities/:itemId/edit",
              component: () => import("./pages/admin/_layout-manage.vue"),
              children: [
                {
                  path: "",
                  component: () => import("./pages/admin/event/activities-manage.vue"),
                  meta: { entity: "activities" },
                },
              ],
            },
            {
              path: "teams",
              component: () => import("./pages/admin/_layout-list.vue"),
              children: [
                {
                  path: "",
                  component: () => import("./pages/admin/event/teams-list.vue"),
                  meta: { entity: "teams" },
                },
              ],
            },
            {
              path: "teams/create",
              component: () => import("./pages/admin/_layout-manage.vue"),
              children: [
                {
                  path: "",
                  component: () => import("./pages/admin/event/teams-manage.vue"),
                  meta: { entity: "teams" },
                },
              ],
            },
            {
              path: "teams/import",
              component: () => import("./pages/admin/_layout-properties.vue"),
              children: [
                {
                  path: "",
                  component: () => import("./pages/admin/event/teams-import.vue"),
                  meta: { entity: "teams" },
                },
              ],
            },
            {
              path: "teams/:itemId/edit",
              component: () => import("./pages/admin/_layout-manage.vue"),
              children: [
                {
                  path: "",
                  component: () => import("./pages/admin/event/teams-manage.vue"),
                  meta: { entity: "teams" },
                },
              ],
            },
            {
              path: "photos",
              component: () => import("./pages/admin/_layout-list.vue"),
              children: [
                {
                  path: "",
                  component: () => import("./pages/admin/event/photos-list.vue"),
                  meta: { entity: "photos" },
                },
              ],
            },
            {
              path: "photos/:itemId/properties",
              component: () => import("./pages/admin/_layout-properties.vue"),
              children: [
                {
                  path: "",
                  component: () => import("./pages/admin/event/photos-manage.vue"),
                  meta: { entity: "photos" },
                },
              ],
            },
            {
              path: "settings",
              component: () => import("./pages/admin/event/settings.vue"),
            },
            {
              path: "*",
              component: NotFound,
            },
          ],
        },
        {
          path: "*",
          component: NotFound,
        },
      ],
    },
    {
      path: "/login",
      beforeEnter(to, from, next) {
        store.dispatch("AUTHENTICATE").then(() => {
          if (!store.state.me) return next();
          if (to.params.redirect) return next(to.params.redirect);
          return next("/admin");
        });
      },
      component: () => import("./pages/login.vue"),
    },
    {
      path: "/resetpassword/:token",
      component: () => import("./pages/resetPassword.vue"),
    },
    {
      path: "/*",
      component: NotFound,
    },
  ],
});

export default router;
