import "./styles/styles.scss";
import "./plugins/filters";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import i18n from "./plugins/i18n";
import validator from "./plugins/validator";
import store from "./store";
import axios from "./plugins/axios";
import "@mdi/font/scss/materialdesignicons.scss";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/fr";
dayjs.locale(i18n.locale);
dayjs.extend(utc);
dayjs.extend(timezone);

Vue.config.productionTip = false;

let initVue = function (settings) {
  // Save settings to store
  store.commit("SETTINGS", settings);

  new Vue({
    router,
    vuetify,
    i18n,
    validator,
    store,
    axios,
    render: (h) => h(App),
  }).$mount("#app");
};

// Load settings from backend before vue init
axios
  .get(`/backoffice/public/settings`, {
    cache: (settings) => store.commit("SETTINGS", settings),
  })
  .then(initVue);
