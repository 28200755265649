<template>
  <v-app
    :class="{
      'primary-color-is-bright': isPrimaryColorBright,
      'secondary-color-is-bright': isSecondaryColorBright,
      'accent-color-is-bright': isAccentColorBright,
    }"
  >
    <v-snackbar
      v-for="(message, index) in $store.state.messages"
      :key="index"
      :color="message.type || 'info'"
      value="message.message"
      top
      :timeout="message.timeout || 7000"
      class="mt-5"
    >
      {{ message.key ? $t(message.key, message.params) : message.message }}
      <v-icon color="white" v-if="message.reactionAddons">{{ message.reactionAddons.type }}</v-icon>
    </v-snackbar>
    <v-fade-transition mode="out-in">
      <v-overlay :value="$store.state.loading" opacity="0.7" z-index="20">
        <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
      </v-overlay>
    </v-fade-transition>
    <v-fade-transition mode="out-in">
      <router-view />
    </v-fade-transition>
  </v-app>
</template>

<script>
import isColorBright from "./util/isColorBright";

export default defineComponent({
  name: "App",
  computed: {
    isPrimaryColorBright() {
      return isColorBright(this.$vuetify.theme.themes["light"].primary);
    },
    isSecondaryColorBright() {
      return isColorBright(this.$vuetify.theme.themes["light"].secondary);
    },
    isAccentColorBright() {
      return isColorBright(this.$vuetify.theme.themes["light"].accent);
    },
  },
});
</script>
