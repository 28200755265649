import Vue from "vue";
import i18n from "./i18n";

/**
 * Add custom rules here
 */
const customRules = {
  required: (v) => !!v,
  requiredIf: (v, condition) => {
    return condition ? !!v && v.length !== 0 : true;
  },
  maxLength: (v, max) => !v || v.length <= max,
  minLength: (v, min) => !v || v.length >= min,
  min: (v, min) => !v || v >= min,
  isUrl: (v) => !v || /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/.test(v),
  nonNegative: (v) => !v || v >= 0,
  nonNegativeOrEmpty: (v) => !v || v >= 0,
  passwordMatch: (v, confirm) => !v || v === confirm,
  isNumber: (v) => !v || !isNaN(Number.parseInt(v)),
  isHexColor: (v) => !v || /^#[0-9A-F]{6}$/i.test(v),
  arrayContainAtLeastOneElement: (v) => !v || v.length > 0,
  isEmail: (v) => !v || /^[a-zA-Z0-9+_-]+(?:\.[a-zA-Z0-9+_-]+)*@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/.test(v),
};

const rules = {};

Object.keys(customRules).forEach(function (key) {
  rules[key] = (...args) => {
    return (value) => {
      let valid = false;
      try {
        valid = customRules[key](value, ...args);
      } catch (err) {
        // if value is empty and rule not handling this case, return true
        if (!value) return true;
      }
      return (
        valid || i18n.t(`errors.validator.${key}`, args.length === 1 && typeof args[0] === "object" ? args[0] : args)
      );
    };
  };
});

Vue.prototype.$v = rules;
export default rules;
