import Vue from "vue";
import dayjs from "dayjs";

export const date = Vue.filter("date", function (value) {
  if (!value) return "";
  return dayjs(value).format("DD/MM/YYYY");
});

export const datetime = Vue.filter("datetime", function (value) {
  if (!value) return "";
  return dayjs(value).format("DD/MM/YYYY HH:mm:ss");
});

export const datetime_2 = Vue.filter("datetime_2", function (value) {
  if (!value) return "";
  return dayjs(value).format("DD MMMM YYYY - HH:mm");
});

export const time = Vue.filter("time", function (value) {
  if (!value) return "";
  return dayjs(value).format("HH:mm");
});

export const duration = Vue.filter("duration", function (value) {
  if (!value) return "";
  return `${
    parseInt(value / 60) == 0 ? "" : parseInt(value / 60) + "h"
  }${value % 60 == 0 ? "" : (value % 60) + " min"}`;
});

export const amount = Vue.filter("amount", function (value, currency) {
  return (
    (Number(value) || 0)
      .toFixed(2)
      .replace(/-/g, "-\u00a0")
      .replace(/(\d)(?=(\d{3})+(\.|$))/g, "$1\u00a0")
      .replace(".", ",") +
    (currency
      ? "\u00a0" +
        ({
          EUR: "€",
          USD: "$",
          GBP: "£",
        }[currency] || currency)
      : "")
  );
});

export const number = Vue.filter("number", function (value) {
  return ("" + (Number(value) || 0))
    .replace(/-/g, "-\u00a0")
    .replace(/(\d)(?=(\d{3})+(\.|$))/g, "$1\u00a0")
    .replace(".", ",");
});
