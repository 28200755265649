import Vue from "vue";
import Vuex, { Store } from "vuex";
import axios from "./plugins/axios";
import dayjs from "dayjs";

Vue.use(Vuex);

const store = new Store({
  state() {
    return {
      messages: [],
      me: null,
      event: null,
      loading: false,
    };
  },
  mutations: {
    LOADING(state, loading) {
      state.loading = !!loading;
    },
    SUCCESS(state, message) {
      state.messages.push({
        type: "success",
        message: message,
        timeout: 4000,
      });
    },
    ERROR(state, message) {
      state.messages.push({
        type: "error",
        message: message,
      });
    },
    WARNING(state, message) {
      state.messages.push({
        type: "warning",
        message: message,
      });
    },
    INFO(state, message) {
      state.messages.push({
        type: "info",
        message: message,
        timeout: 6000,
      });
    },
    NOTIFICATION(state, notification) {
      notification.date = notification.date || dayjs().valueOf();
      state.notifications.push(notification);
    },
    MESSAGE(state, [type, message, timeout]) {
      state.messages.push({
        type: type,
        message: message,
        timeout: timeout,
      });
    },
    I18N_MESSAGE(state, [type, key, params, timeout]) {
      state.messages.push({
        type: type,
        key: key,
        params: params,
        timeout: timeout,
      });
    },
    AUTH(state, auth) {
      state.auth = auth;
    },
    ME(state, me) {
      state.me = me;
    },
    EVENT(state, event) {
      state.event = event;
    },
    SET_SERVER_TIME(state, time) {
      state.serverTime = time;
      state.timeDiff = time.diff(dayjs());
    },
    INCREMENT_SERVER_TIME(state) {
      state.serverTime = dayjs().add(state.timeDiff, "ms");
    },
    SETTINGS(state, settings) {
      state.settings = settings;
    },
  },
  actions: {
    async AUTHENTICATE({ commit, state }, auth) {
      /**
       * Load auth if not passed in parameters
       */
      if (typeof auth === "undefined") {
        if (state.auth) {
          auth = state.auth;
        } else {
          auth = JSON.parse("" + localStorage.getItem("auth"));
        }
      }
      if (!auth || auth.auth == false) {
        commit("AUTH", null);
        commit("ME", null);
        localStorage.setItem("auth", null);
        return null;
      }
      /**
       * Calculate expires_date if not set
       */
      if (auth && !auth.expires_date) {
        auth.expires_date = new Date().getTime() + (auth.expires_in || 14400) * 1000;
      }
      /**
       * Check validity of auth token
       */
      if (!auth || auth.expires_date < new Date().getTime() + 60000) {
        commit("AUTH", null);
        commit("ME", null);
        delete axios.defaults.headers.common["Authorization"];
        localStorage.removeItem("auth");
        return null;
      }
      /**
       * Don't load me from server if already loaded and auth still valid
       */
      if (state.auth === auth && state.me) {
        return state.me;
      }
      /**
       * Load me from server
       */
      commit("AUTH", auth);
      if (auth) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${auth.access_token}`;
        localStorage.setItem("auth", JSON.stringify(auth));
      } else {
        delete axios.defaults.headers.common["Authorization"];
        localStorage.removeItem("auth");
      }
      try {
        commit("ME", await axios.get("backoffice/admin/me"));
      } catch (err) {
        console.error(err);
        commit("AUTH", null);
        commit("ME", null);
        delete axios.defaults.headers.common["Authorization"];
        localStorage.removeItem("auth");
      }
      return state.me;
    },
  },
});

export function useStore() {
  return store;
}

export default store;
